<template>
  <section class="goods-wrapper">
    <card title="库存管理">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="checkHistory"
        >历史</el-button
      >
      <el-input
        placeholder="请输入商品名称"
        v-model="searchText"
        @keyup.enter.native="search()"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search()"
        ></el-button>
      </el-input>
      <el-table :data="searchList" border class="search-table">
        <el-table-column
          label="商品名称"
          prop="pName"
          align="center"
        ></el-table-column>
        <el-table-column
          label="库存量"
          prop="number"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.editNum"
              size="small"
              style="width:140px"
            >
              <el-select v-model="scope.row.editType" slot="prepend">
                <el-option label="+" :value="0"></el-option>
                <el-option label="-" :value="1"></el-option>
              </el-select>
            </el-input>
            <el-button
              size="mini"
              @click="editGoods(scope.$index, scope.row)"
              style="margin-left:10px"
              >确定</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleSearchCurrentChange"
        :current-page="searchCurrentPage"
        :page-size="searchPageSize"
        layout="total, prev, pager, next,jumper"
        :total="searchTotalCount"
        style="margin: 20px 0 0 30px;"
      >
      </el-pagination>

      <div class="goodstable-wrapper">
        <table class="goodsTable">
          <tr class="tableHeader">
            <td>商品名</td>
            <td>库存</td>
            <td>商品名</td>
            <td>库存</td>
          </tr>
          <tr v-for="index in rowCount" :key="index">
            <td class="column">{{ goodsList[index * 2 - 2].pName }}</td>
            <td>{{ goodsList[index * 2 - 2].number }}</td>
            <td class="column">
              {{
                goodsList[index * 2 - 1] !== undefined
                  ? goodsList[index * 2 - 1].pName
                  : ""
              }}
            </td>
            <td>
              {{
                goodsList[index * 2 - 1] !== undefined
                  ? goodsList[index * 2 - 1].number
                  : ""
              }}
            </td>
          </tr>
        </table>

        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="PageSize"
          layout="total, prev, pager, next,jumper"
          :total="totalCount"
          style="margin: 20px 0 0 30px;"
        >
        </el-pagination>
      </div>
    </card>
  </section>
</template>

<script>
import card from "../../components/card";
export default {
  components: {
    card
  },
  data() {
    return {
      searchText: "",
      selectType: "1",
      editNum: 0,
      searchList: [],
      searchCurrentPage: 1,
      searchPageSize: 10,
      currentPage: 1,
      PageSize: 10,
      totalCount: 0,
      goodsList: []
    };
  },
  computed: {
    rowCount: function() {
      return Math.ceil(this.goodsList.length / 2);
    },
    searchTotalCount: function() {
      return this.searchList.length;
    }
  },
  methods: {
    editGoods(index, row) {
      if (parseInt(row.editNum) % 1 === 0) {
        this.$axios({
          url: "/back/stock/operation",
          params: {
            number: row.editNum,
            operation: row.editType,
            pid: row.pid
          }
        })
          .then(res => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.$openLoading();
              this.getGoods();
            } else {
              this.$message({
                type: "error",
                message: res.res.msg
              });
            }
          })
          .catch(err => {
            this.$message({
              type: "error",
              message: "操作失败"
            });
          });
      } else {
        this.$message({
          type: "error",
          message: "请输入整数"
        });
      }
    },
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val;
      this.getGoods();
    },
    getGoods() {
      this.$axios({
        url: "/back/stock/getAllStock",
        params: {
          page: this.currentPage
        }
      })
        .then(res => {
          if (res.data.code == 200) {
            this.PageSize = res.data.data.pageSize;
            this.totalCount = res.data.data.total;
            this.goodsList = res.data.data.list;
            this.search();
          } else {
            this.$message({
              type: "error",
              message: "库存信息获取失败"
            });
          }
          setTimeout(() => {
            this.$openLoading().close();
          }, 200);
        })
        .catch(err => {
          setTimeout(() => {
            this.$openLoading().close();
          }, 200);
          this.$message({
            type: "error",
            message: "库存订单信息获取失败"
          });
        });
    },
    handleSearchCurrentChange(val) {
      // 改变默认的页数
      this.searchCurrentPage = val;
      this.search();
    },
    search() {
      if (this.searchText) {
        this.searchList = this.goodsList.filter(item => {
          if (item.pName.indexOf(this.searchText) != -1) {
            return true;
          }
        });
        this.searchList.forEach(item => {
          this.$set(item, "editType", 0);
          this.$set(item, "editNum", 0);
        });
        if (this.searchList.length === 0) {
          this.$message({
            type: "error",
            message: "没有搜索到相关商品"
          });
        }
      } else this.searchList = [];
    },
    checkHistory() {
      this.$router.push({
        name: "goodshistory"
      });
    }
  },
  created() {
    this.$openLoading();
    this.getGoods();
  }
};
</script>

<style lang="less">
.goods-wrapper {
  .el-select {
    .el-input {
      width: 60px !important;
    }
  }
}

.search-table {
  max-height: 50vh;
  overflow: scroll;
  margin-top: 30px;
}

.goodstable-wrapper {
  max-height: 70vh;
  overflow: scroll;
  margin-top: 30px;

  .goodsTable {
    font-size: 14px;
    color: #606266;
    border-collapse: collapse;
    width: 100%;
    text-align: center;

    .tableHeader {
      color: #909399;
      font-weight: 700;
    }
    tr {
      td {
        border: 1px solid #e6eaee;
        width: 150px;
        height: 45px;
        line-height: 45px;
        box-sizing: border-box;
        padding: 0 10px;
      }
      .column {
        color: #393c3e;
      }
    }
  }
}
</style>
